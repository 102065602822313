import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SalesStorySettingsButtonComponent } from '@shared/components/sales-story-settings/sales-story-settings-button.component';
import { DirectivesModule } from '@shared/directives/directives.module';
import { InlineEditVariablesService, ModalExecutor } from '@shared/services';

@NgModule({
  declarations: [SalesStorySettingsButtonComponent],
  imports: [CommonModule, DirectivesModule],
  providers: [InlineEditVariablesService, ModalExecutor],
  exports: [SalesStorySettingsButtonComponent],
})
export class SalesStorySettingsButtonModule {}
