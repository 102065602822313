import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { forkJoin, from, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as _ from 'lodash-es';
import * as apiConstant from '@core/constant';
import { getSearchQuery } from '@core/utils';
import { ShareableLinkResponse } from '@shared/models';
import { ResponseType } from '@core/model';
import {
  ExtendedInvitedUsersList,
  InvitedUserList,
  ReceiverEmails,
  SendEmailResponse,
  User,
  UserListResponse,
} from './presentation-sharing.interfaces';
import { LocalStorageService } from '@core/service';
import { TableRequestParamsService } from '@shared/services';
import { DefaultInvitedUsersPaginationRequestParams } from './presentation-sharing-contants';

@Injectable()
export class PresentationSharingService extends TableRequestParamsService {
  private readonly EMAIL_REGEXP =
    /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;

  constructor(private http: HttpClient, private localStorage: LocalStorageService) {
    super(DefaultInvitedUsersPaginationRequestParams, null, null);
  }

  getAgencyNames(ids: Array<number | string>) {
    return this.http
      .post<any>(`${apiConstant.baseUrl}${apiConstant.agenciesNames}`, ids)
      .pipe(catchError(this.handleError));
  }

  sendShareableLinkViaEmail(
    emails: ReceiverEmails[],
    note: string,
    presentationId: number,
    isShared = false
  ): Observable<SendEmailResponse> {
    const params = { emails, note };
    const url = isShared
      ? `${apiConstant.baseSharedUrl}${apiConstant.shareByEmail}`
      : `${apiConstant.presentation}/${presentationId}/${apiConstant.shareByEmail}`;

    return this.http.post<ResponseType<SendEmailResponse>>(`${url}`, params).pipe(
      map(response => response.data),
      catchError(this.handleError)
    );
  }

  getSharedToken(presentationId: number): Observable<ShareableLinkResponse> {
    return this.http
      .get<ResponseType<ShareableLinkResponse>>(`${apiConstant.baseSharedUrl}token`, {
        params: getSearchQuery({ presentationId }),
      })
      .pipe(
        map(res => res.data),
        catchError(this.handleError)
      );
  }

  getExtendedinvitedUsersList(
    presentationId: number,
    isShared: boolean,
    page = 1
  ): Observable<{ data: ExtendedInvitedUsersList[]; totalCount: number }> {
    return this.getInvitedUserList(presentationId, isShared, page).pipe(
      mergeMap(({ invitedUsers, totalCount }) => {
        const userIds = invitedUsers.map(user => user.receiverUserId).filter(id => id !== null);

        return userIds.length
          ? forkJoin({
              invitedUsers: of(invitedUsers),
              userDetails: this.getUsersByIds(userIds),
              totalCount: of(totalCount),
            })
          : of({ invitedUsers, userDetails: [], totalCount });
      }),
      map(({ invitedUsers, userDetails, totalCount }) => {
        const data = invitedUsers.map(invitedUser => {
          const userDetail = userDetails.find(detail => detail.id === invitedUser.receiverUserId);

          if (userDetail) {
            return {
              ...invitedUser,
              firstName: userDetail.firstName,
              lastName: userDetail.lastName,
              avatarName: userDetail.avatarName,
            };
          }

          return invitedUser;
        });

        return { data, totalCount };
      })
    );
  }

  getUsersByEmail(email: string, isPastedMultipleEmails = false, size = 5): Observable<User[]> {
    const params = {
      searchValue: email,
      size: size,
      active: true,
    };

    return this.http.get<UserListResponse>(`/um/v2${apiConstant.filteredUsers}`, { params }).pipe(
      map(res => {
        let users = res.data.content;

        if (isPastedMultipleEmails) {
          users = users.filter(user => user.emails[0] === email);
        }

        return users.map(user => ({
          ...user,
          email: user.emails[0],
        }));
      }),
      catchError(this.handleError)
    );
  }

  isValidEmail(email: string): boolean {
    return this.EMAIL_REGEXP.test(email.trim());
  }

  extractEmailsFromInput(pastedText: string): string[] {
    const parts = pastedText.split(/[,;\s\n]+/);

    return parts
      .map(part => {
        const angleBracketMatch = part.match(/<([^>]+)>/);

        if (angleBracketMatch) {
          return angleBracketMatch[1].trim();
        }

        if (part.includes('@')) {
          return part.trim();
        }

        return '';
      })
      .filter(Boolean);
  }

  getAvatar(email: string, firstName?: string, avatarName?: string): { url: string; initial: string } {
    if (avatarName) {
      const s3BucketName = this.localStorage.getNotJSONData('s3UmBucketName');
      const s3UmBucketUserImages = this.localStorage.getNotJSONData('s3UmBucketUserImages');
      const url = `https://${s3BucketName}/${s3UmBucketUserImages}/${avatarName}`;

      return { url, initial: '' };
    } else {
      const initial = firstName ? firstName[0].toUpperCase() : email?.[0]?.toUpperCase();

      return { url: '', initial };
    }
  }

  copyToClipboard(url: string, clientName: string): Observable<void> {
    const htmlLink = `<a href="${url}">Ensight Presentation${clientName ? ` - ${clientName}` : ''}</a>`;
    const clipboardPromise = navigator.clipboard.write([
      new ClipboardItem({
        'text/plain': new Blob([url], { type: 'text/plain' }),
        'text/html': new Blob([htmlLink], { type: 'text/html' }),
      }),
    ]);

    return from(clipboardPromise);
  }
  //TODO: need to remove this method
  generateSharedLink(token: string) {
    return `${location.origin}/shared-presentation/${token}`;
  }

  private getInvitedUserList(
    presentationId: number,
    isShared: boolean,
    page: number
  ): Observable<{ invitedUsers: InvitedUserList[]; totalCount: number }> {
    const params = { PAGE: page };
    const url = isShared ? apiConstant.baseSharedUrl : `${apiConstant.presentation}/${presentationId}/`;

    return this.http
      .get<ResponseType<{ data: InvitedUserList[]; count: number }>>(`${url}${apiConstant.shareByEmail}`, {
        params,
      })
      .pipe(
        map(response => {
          return {
            invitedUsers: response.data.data,
            totalCount: response.data.count,
          };
        }),
        catchError(this.handleError)
      );
  }

  private getUsersByIds(ids: number[]): Observable<User[]> {
    return this.http
      .post<ResponseType<User[]>>(`/um/v2${apiConstant.userIds}`, ids)
      .pipe(map((res: ResponseType<User[]>) => res.data || []));
  }

  private handleError(error: any) {
    return throwError(`${error.status} - ${error.statusText}`);
  }
}
