import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ModalConfig, ModalRef } from '@assurance/bootstrap';

@Component({
  selector: 'ensight-custom-page-settings-modal',
  template: `
    <ac-modal-header (close)="modal.close(false)">
      <header class="title">
        {{ config.data?.title || 'Confirm' }}
      </header>
    </ac-modal-header>

    <ac-modal-body>
      <div class="content" [innerHTML]="config.data?.message"></div>
    </ac-modal-body>

    <ac-modal-footer>
      <div class="buttons-block">
        <ac-button type="submit" class="btn-confirm-submit" (click)="modal.close(true)">Yes</ac-button>
        <ac-button class="secondary btn-confirm-cancel" (click)="modal.close(false)">Cancel</ac-button>
      </div>
    </ac-modal-footer>
  `,
  styleUrls: ['./confirm-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmModalComponent {
  constructor(public config: ModalConfig, public modal: ModalRef) {}
}
