import { Pipe, PipeTransform } from '@angular/core';

import { ThousandsSeparatorService } from '@shared/services';

@Pipe({
  name: 'thousandsSeparator',
})
export class ThousandsSeparatorPipe implements PipeTransform {
  constructor(private thousandsSeparatorService: ThousandsSeparatorService) {}

  transform(value: number): string {
    return this.thousandsSeparatorService.getFormattedValue(value);
  }
}
