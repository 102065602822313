import { ValidatorFn, Validators } from '@angular/forms';

import { CustomPageInsertMetadata } from '@shared/models';
import { ExtendedPlaceholderMetadata, TextPlaceholder, VariablePlaceholder } from '@core/model';
import { INSERT_TYPE } from '@core/enums';

export const getValidatorsForEditablePlaceholder = (
  insert:
    | CustomPageInsertMetadata<TextPlaceholder & VariablePlaceholder>
    | ExtendedPlaceholderMetadata<TextPlaceholder & VariablePlaceholder>
): ValidatorFn[] => {
  return insert.insertType === INSERT_TYPE.text
    ? [Validators.required, ...getValidatorsForTextValue(insert.placeholderMaxLength)]
    : [Validators.required, ...getValidatorsForNumberValue(insert)];
};

export const getValidatorsForTextValue = (placeholderMaxLength: number) => {
  return [Validators.maxLength(placeholderMaxLength)];
};

export const getValidatorsForNumberValue = insert => {
  return [
    Validators.max(insert.placeholderMaxValue),
    Validators.min(insert.placeholderMinValue),
    Validators.pattern(`\\d+${insert.placeholderDecimals ? `(\\.\\d{0,${insert.placeholderDecimals}})?` : ''}`),
  ];
};
