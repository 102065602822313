import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[epInputPattern]',
})
export class InputPatternDirective {
  @Input() inputPattern: string;

  constructor(private elementRef: ElementRef) {}

  @HostListener('input', ['$event']) onInput($event) {
    const preventDefault = this.checkPendingValue($event, this.elementRef.nativeElement.value);

    if (preventDefault) {
      this.elementRef.nativeElement.value = '';
      this.elementRef.nativeElement.dispatchEvent(new Event('input'));
    }
  }

  @HostListener('keypress', ['$event']) onInputChange($event) {
    this.generatePendingValue($event.key, $event);
  }

  private generatePendingValue(value, $event): void {
    const from = this.elementRef.nativeElement.selectionStart;
    const to = this.elementRef.nativeElement.selectionEnd;
    const proceedVal = this.elementRef.nativeElement.value.split('');

    proceedVal.splice(from, to - from, value);

    const pendingValue = proceedVal.join('');

    this.checkPendingValue($event, pendingValue);
  }

  private checkPendingValue($event, key): boolean {
    const match = key.match(new RegExp(this.inputPattern));
    const preventDefault = !(match && match.length) && key !== '';

    if (preventDefault) {
      $event.preventDefault();
    }

    return preventDefault;
  }
}
