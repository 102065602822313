import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { AppState } from '../../../../reducers';
import { globalPdfGeneration } from '@ngrx-app/global.actions';
import { SharedSocketService, Global } from '@shared/services';

@Injectable()
export class PdfExportService {
  constructor(public global: Global, public socketService: SharedSocketService, public store: Store<AppState>) {}

  sendPDFExportShared(): void {
    this.store.dispatch(globalPdfGeneration({ payload: true }));
    this.socketService.sendPDFExportShared(this.global.getSharedToken(), this.global.getPresentation.formattedDate);
  }
}
