import { Directive, HostBinding } from '@angular/core';
import { InsertContentService } from '@shared/components/insert-content/insert-content.service';

@Directive({
  selector: '[epDisableTabIndex]',
})
export class DisableTabIndexDirective {
  @HostBinding() tabIndex: number;

  constructor(private insertContentService: InsertContentService) {
    this.tabIndex = this.insertContentService.getIsActiveBulkEdit ? -1 : 0;
  }
}
