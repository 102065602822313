import { Injector, ComponentFactoryResolver, Injectable, ComponentRef, Inject } from '@angular/core';

import { isNil } from 'lodash-es';

import { ExtendedChartMetadata, ExtendedPlaceholderMetadata, TabsValue } from '@core/model';
import { INSERT_TYPE } from '@core/enums';
import { ImageHandlerService, ThousandsSeparatorService } from '@shared/services';
import { DEFAULT_CUSTOM_TABLE_PRODUCT } from '@shared/constants';
import { ChartComponent } from '@shared/components/chart/chart.component';

import { CustomTableComponent } from './custom-table/custom-table.component';
import { InsertDropdownComponent } from './insert-dropdown/insert-dropdown.component';
import { ProductSelectorComponent } from './product-selector/product-selector.component';
import { TabInsertComponent } from './tab-insert/tab-insert.component';
import { InlineEditableVariableComponent } from './inline-editable-variable/inline-editable-variable.component';
import { DOCUMENT_TOKEN } from '@core/constant';

@Injectable()
export class InsertContentService {
  private _isActiveBulkEdit = false;

  set setIsActiveBulkEdit(value: boolean) {
    this._isActiveBulkEdit = value;
  }

  get getIsActiveBulkEdit(): boolean {
    return this._isActiveBulkEdit;
  }

  constructor(
    private injector: Injector,
    public componentFactoryResolver: ComponentFactoryResolver,
    private thousandsSeparatorService: ThousandsSeparatorService,
    private imageHandlerService: ImageHandlerService,
    @Inject(DOCUMENT_TOKEN) private document: Document
  ) {}

  createEditablePlaceholderMarkup(data) {
    let placeholderMarkup: HTMLElement | HTMLImageElement;

    switch (data.insertType) {
      case INSERT_TYPE.text:
      case INSERT_TYPE.dropdown:
        placeholderMarkup = this.document.createElement('span');
        this.setTabindex(placeholderMarkup);
        placeholderMarkup.innerHTML = data.value;
        break;
      case INSERT_TYPE.variable:
        placeholderMarkup = this.document.createElement('span');
        this.setTabindex(placeholderMarkup);
        placeholderMarkup.innerHTML = this.thousandsSeparatorService.getFormattedValue(data.value);
        break;
      case INSERT_TYPE.image:
        placeholderMarkup = new Image(data.width, data.height);
        this.setTabindex(placeholderMarkup);

        // eslint-disable-next-line no-case-declarations
        const fileLink = this.imageHandlerService.getImageLink(data.filesLinks, data.value, data.selectedImage);

        (placeholderMarkup as HTMLImageElement).src = this.imageHandlerService.buildImageUrl(fileLink);
        break;
    }

    this.insertComponentIntoBody(data.elementRef, placeholderMarkup);
  }

  createInlineEditableMarkup(data): ComponentRef<InlineEditableVariableComponent> {
    const factory = this.componentFactoryResolver.resolveComponentFactory(InlineEditableVariableComponent);
    const componentRef = factory.create(this.injector);

    componentRef.instance.metadata = data;
    componentRef.instance.isActiveBulkEdit = this.getIsActiveBulkEdit;

    componentRef.hostView.detectChanges();

    const { nativeElement } = componentRef.location;

    this.insertComponentIntoBody(data.elementRef, nativeElement);

    data.elementRef.classList.add('inline-edit-variable');

    return componentRef;
  }

  createCustomTableComponent(data) {
    const factory = this.componentFactoryResolver.resolveComponentFactory(CustomTableComponent);
    const componentRef = factory.create(this.injector);
    const filteredTableColumn = data.columns.filter(column => column.hideFormula?.trim() !== '0');
    componentRef.instance.columns = filteredTableColumn;

    const tableData = [];

    const linesStartFrom = data.linesStartFrom - 1;
    const linesEndsOn = data.linesEndsOn - 1;

    const productIndex = +data.productIndex || DEFAULT_CUSTOM_TABLE_PRODUCT;

    for (let i = linesStartFrom; i <= linesEndsOn; i++) {
      const tableRow = filteredTableColumn.map(column => {
        const columnIndex = data.columns.indexOf(column);

        return data.calculatedData?.[productIndex]?.[columnIndex]?.data[i];
      });

      tableData.push(tableRow);
    }

    componentRef.instance.rowHighlighted = !(data.highlightedRowNumber?.trim() === '')
      ? (componentRef.instance.rowHighlighted = Number(data.highlightedRowNumber?.trim()))
      : undefined;
    componentRef.instance.tableData = tableData;

    componentRef.hostView.detectChanges();
    const { nativeElement } = componentRef.location;
    data.elementRef.innerHTML = '';
    data.elementRef.appendChild(nativeElement);

    return componentRef;
  }

  createChartComponent(data: ExtendedPlaceholderMetadata<ExtendedChartMetadata>) {
    const factory = this.componentFactoryResolver.resolveComponentFactory(ChartComponent);
    const componentRef = factory.create(this.injector);
    componentRef.instance.debounceTime = 700;
    componentRef.instance.colorScheme = <string[]>data.colorScheme;
    data.data = data.data.map(item => {
      const metric = (data.metrics as any[]).find(
        metric => metric.metricKey === item.metricId && metric.order === item.order
      );
      metric &&
        ['hideMetric', 'dashedMetric', 'dashLength', 'dashInterval', 'hideMetricOnChart'].forEach(
          key => (item[key] = metric[key])
        );

      return item;
    });
    componentRef.instance.data = data.data;
    componentRef.instance.options = data.options;
    componentRef.instance.pinType = data.pin;
    componentRef.instance.chartKey = data.id;
    componentRef.instance.chartWidth = data.width;
    componentRef.instance.metricDataSource = data.metricDataSource;
    componentRef.instance.options.chart.chartHeight = data.chartHeight;
    componentRef.instance.options.chart.chartFontSizeAxesLabels = data.chartFontSizeAxesLabels;
    componentRef.instance.options.chart.chartFontSizeAxesNames = data.chartFontSizeAxesNames;
    componentRef.instance.options.chart.chartFontSizeToolTipLabel = data.chartFontSizeToolTipLabel;
    componentRef.instance.options.chart.chartFontSizeToolTipNumber = data.chartFontSizeToolTipNumber;
    componentRef.instance.options.chart.chartFontSizeXAxisValuePin = data.chartFontSizeXAxisValuePin;
    componentRef.instance.chartFontSize = data.chartFontSize;
    componentRef.instance.chartTheme = data.chartTheme;
    componentRef.instance.optimalVerticalPosition = data.optimalVerticalPosition;
    componentRef.instance.isActiveBulkEdit = this.getIsActiveBulkEdit;
    componentRef.instance.tooltipTotalOptions = {
      pinTotalType: data.pinTotalType,
      pinTotalLabel: data.pinTotalLabel,
      pinTotalColor: data.pinTotalColor,
    };
    componentRef.instance.xAxisConfig = {
      xAxisMetric: data.xAxisMetric,
      xAxisName: data.xAxisName,
      xAxisSource: data.xAxisSource,
    };
    componentRef.hostView.detectChanges();
    const { nativeElement } = componentRef.location;
    this.insertComponentIntoBody(data.elementRef, nativeElement);

    return componentRef;
  }

  createButtonComponent(data) {
    const buttonElement = this.document.createElement('button');
    this.setTabindex(buttonElement);
    buttonElement.innerHTML = data.buttonLabel;
    this.insertComponentIntoBody(data.elementRef, buttonElement);
  }

  createDropdownComponent(data): ComponentRef<InsertDropdownComponent> {
    const factory = this.componentFactoryResolver.resolveComponentFactory(InsertDropdownComponent);
    const componentRef = factory.create(this.injector);
    componentRef.instance.options = data.dropdownOptions;
    componentRef.instance.title = data.placeholderName;
    const selectedValue = data.dropdownOptions.some(option => option.value?.trim() === data.value?.trim())
      ? data.value
      : data.dropdownOptions[data.value]?.value;

    componentRef.instance.value = selectedValue || data.placeholderSelectedValue;
    componentRef.instance.id = data.id;

    componentRef.hostView.detectChanges();
    const { nativeElement } = componentRef.location;
    this.insertComponentIntoBody(data.elementRef, nativeElement);

    return componentRef;
  }

  createTabInsertComponent(data): ComponentRef<TabInsertComponent> {
    const factory = this.componentFactoryResolver.resolveComponentFactory(TabInsertComponent);
    const componentRef = factory.create(this.injector);

    componentRef.instance.tabs = data.tabs as TabsValue[];
    componentRef.instance.id = data.id;
    componentRef.instance.selectedTab = data.value || data.placeholderSelectedTab;
    componentRef.instance.tabTitle = data.placeholderName;

    componentRef.hostView.detectChanges();
    const { nativeElement } = componentRef.location;
    this.insertComponentIntoBody(data.elementRef, nativeElement);

    return componentRef;
  }

  createProductSelectorComponent(data): ComponentRef<ProductSelectorComponent> {
    const factory = this.componentFactoryResolver.resolveComponentFactory(ProductSelectorComponent);
    const componentRef = factory.create(this.injector);

    componentRef.instance.template = data.placeholderProductSelectorHtml;
    componentRef.instance.id = data.id;
    componentRef.instance.selectedValue = !isNil(Number(data.value))
      ? Number(data.value)
      : Number(data.placeholderSelectedProduct);
    componentRef.instance.title = data.placeholderName;
    componentRef.hostView.detectChanges();

    const { nativeElement } = componentRef.location;
    this.insertComponentIntoBody(data.elementRef, nativeElement);

    return componentRef;
  }

  private insertComponentIntoBody(elementRef, nativeElement) {
    elementRef.innerHTML = '';
    elementRef.classList.remove('app-chart');
    elementRef.removeAttribute('style');
    elementRef.appendChild(nativeElement);
  }

  private setTabindex(element: HTMLElement): void {
    element.setAttribute('tabIndex', this._isActiveBulkEdit ? '-1' : '0');
  }
}
