import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, ValidatorFn } from '@angular/forms';

import { ExtendedPlaceholderMetadata, InsertVariableValueType, VariablePlaceholder } from '@core/model';
import { InsertVariableValueTypes } from '@core/enums';
import { getValidatorsForNumberValue, getValidatorsForTextValue } from '@shared/utils';
import { GAService, ThousandsSeparatorService } from '@shared/services';
import { InsertConfig } from '@shared/models';

@Component({
  selector: 'ep-inline-editable-variable',
  templateUrl: './inline-editable-variable.component.html',
  styleUrls: ['./inline-editable-variable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineEditableVariableComponent implements OnInit {
  @Input() metadata: ExtendedPlaceholderMetadata<VariablePlaceholder>;
  @Input() isActiveBulkEdit: boolean;

  @Output() currentValueChange = new EventEmitter<InsertConfig[]>();
  @Output() updateDependentPageConfig = new EventEmitter<InsertConfig[]>();

  valueType = InsertVariableValueTypes;
  formControl: FormControl;
  isFocused = false;
  numberPattern: string;
  currentValue: string | number;

  private isNumeric: boolean;
  private isText: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private thousandsSeparator: ThousandsSeparatorService,
    private gaService: GAService
  ) {}

  ngOnInit(): void {
    this.isNumeric = this.isNumericPlaceholder(this.metadata.placeholderValueType);
    this.isText = this.isTextPlaceholder(this.metadata.placeholderValueType);
    this.numberPattern = `^\\d+(\\.(\\d{0,${this.metadata?.placeholderDecimals || 0}})?)?$`;
    this.setCurrentValue(this.metadata.value);

    if (this.isActiveBulkEdit) {
      this.showControl();
    }
  }

  @HostListener('document:mousedown', ['$event']) public onClick(event) {
    const isBtnSubmit = event?.target['classList'].contains('save-inline-variable');

    if (isBtnSubmit && this.formControl?.errors) {
      this.validateControl();
      event.preventDefault();

      return;
    }
  }

  showControl(): void {
    this.formControl = new FormControl(this.currentValue, { validators: this.getValidators() });
    this.isFocused = true;
    this.gaService.sendInsertEvent({
      elementName: this.metadata.placeholderName,
      eventAction: 'Inline Variable Insert Clicked',
    });
    this.cdr.detectChanges();
  }

  hideControl(event): void {
    if (event?.relatedTarget?.classList?.contains('save-inline-variable') || this.isActiveBulkEdit) {
      return;
    }

    this.isFocused = false;
    this.formControl.reset();
    this.cdr.detectChanges();
  }

  save(): void {
    if (this.formControl.invalid) {
      return;
    }

    this.setCurrentValue(this.formControl.value);

    const data = { uiId: this.metadata.id, value: this.currentValue };

    if (this.metadata.isVariableUpdateTriggersCompilation) {
      this.currentValueChange.emit([data]);
    } else {
      this.updateDependentPageConfig.emit([data]);
    }

    this.hideControl(null);
  }

  validateControl(): void {
    this.formControl.updateValueAndValidity();
    this.cdr.detectChanges();
  }

  private getValidators(): ValidatorFn[] {
    if (this.isNumeric) {
      return getValidatorsForNumberValue(this.metadata);
    } else if (this.isText) {
      return getValidatorsForTextValue(this.metadata.placeholderMaxLength);
    }
  }

  private isNumericPlaceholder(type: InsertVariableValueType): boolean {
    return type === this.valueType.number || type === this.valueType.percentage || type === this.valueType.currency;
  }

  private isTextPlaceholder(type: InsertVariableValueType): boolean {
    return type === this.valueType.textArea || type === this.valueType.textField;
  }

  private setCurrentValue(value: string | number): void {
    this.currentValue = value && this.isNumeric ? this.thousandsSeparator.getFormattedValue(value as number) : value;
  }
}
