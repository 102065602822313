<div *ngIf="loading" class="spinner-holder">
  <ac-loader></ac-loader>
</div>
<div class="page-settings-container">
  <ensight-custom-page-header
    [isEditMode]="editMode"
    [title]="editMode ? 'Edit Custom Page' : 'New Custom Page'"
    [showSaveBtn]="true"
    [disableSaveBtn]="
      customPageForm?.invalid ||
      loading ||
      dependentPagesLoading ||
      (editMode && isSaveDisabled && !isHTMLBodyChanged)
    "
    [loading]="loading || dependentPagesLoading"
    (onClose)="closeSettings()"
    (onSave)="savePage()"
    [addNoteControl]="note"
  ></ensight-custom-page-header>
  <div class="page-settings-content">
    <form
      *ngIf="customPageForm"
      class="custom-page-form"
      data-at-id="custom-page-form"
      [formGroup]="customPageForm"
    >
      <section class="content-section" data-at-id="custom-page-form-name">
        <label class="label">Name</label>
        <input class="name-control" formControlName="name" maxlength="100" />
      </section>

      <section *ngIf="editMode && isShowVersions" class="content-section">
        <label class="label">Version Name</label>
        <ng-select
          class="ng-select-version"
          bindLabel="versionName"
          bindValue="pageId"
          formControlName="versionId"
          [multiple]="false"
          [items]="customPageVersions.versions"
          [clearable]="false"
          [searchable]="false"
          [placeholder]="'Select Version'"
        ></ng-select>
      </section>

      <section class="content-section" data-at-id="custom-page-form-label">
        <label class="label">Page Label</label>
        <input class="name-control" formControlName="label" maxlength="100" />
      </section>

      <section
        *ngIf="salesConceptSettingsShown"
        class="content-section"
        data-at-id="custom-page-form-sales-concept-label"
      >
        <label class="label">Sales Concept Label</label>
        <input
          class="name-control"
          formControlName="salesConceptLabel"
          maxlength="100"
        />
      </section>

      <section class="content-section">
        <label class="label">Layout</label>
        <se-button-group
          [buttons]="layoutButtons"
          size="medium"
          (onSelectButton)="onSelectLayout($event)"
        ></se-button-group>
      </section>

      <section class="content-section body-section">
        <label class="label">Body</label>
        <ensight-tiny-editor
          controlName="htmlBody"
          [form]="customPageForm"
          class="body-control"
          data-at-id="custom-page-form-editor"
          [customInitConfig]="editorConfig"
        ></ensight-tiny-editor>
      </section>

      <section
        class="content-section choose-presentation-area d-flex justify-content-between align-items-center p-3"
      >
        <div>
          <ng-container *ngIf="getPlaceholders$ | async as placeholders">
            <se-button
              class="secondary bg-white"
              [disabled]="
                !selectedPresentation ||
                !placeholders.length ||
                compilationPreviewInProgress
              "
              (click)="onCompileInserts()"
            >
              Compile All
            </se-button>
          </ng-container>
          <span *ngIf="compilationPreviewInProgress" class="px-4">
            Compilation is in progress...
          </span>
        </div>
        <div>
          <se-button
            class="secondary bg-white"
            (click)="onChoosePresentationModal()"
          >
            Choose Presentation
          </se-button>
          <span class="presentation-name px-4">
            {{ selectedPresentation?.name || 'No Presentation Selected' }}
          </span>
          <button
            *ngIf="selectedPresentation"
            class="btn-clear border-0"
            (click)="onClearPresentation()"
          >
            Clear
            <img src="/assets/icons/close-icon.svg" alt="Close icon" />
          </button>
        </div>
      </section>

      <section
        class="content-section"
        *ngIf="getPlaceholders$ | async as placeholders"
      >
        <ng-container *ngIf="placeholders.length !== 0">
          <label class="label">Inserts</label>
          <div class="insert-sections">
            <div
              [dragula]="dragModelBag"
              [dragulaModel]="placeholders"
              class="insert-sections-container"
            >
              <ng-container *ngFor="let placeholder of placeholders">
                <div
                  class="section-label-container"
                  *ngIf="placeholders | sortInsertLabels: placeholder"
                >
                  <label #sectionLabel class="insert-section-label">
                    {{ placeholders | sortInsertLabels: placeholder }}
                  </label>
                  <div
                    *ngIf="sectionLabel.innerText"
                    class="label-underline"
                  ></div>
                </div>
                <div
                  class="insert-section-item draggable-item"
                  [attr.data-placeholder-id]="placeholder.id"
                >
                  <div class="d-flex">
                    <div *ngIf="placeholder.errorMessage" class="warning-block">
                      <img
                        src="/assets/icons/insert-warning-icon.svg"
                        alt="Warning icon"
                      />
                    </div>
                    <div class="d-flex flex-row align-items-center">
                      <p class="placeholder-name pr-2 m-0">
                        {{
                          placeholder.chartName ||
                            placeholder.placeholderName ||
                            'Products Description'
                        }}
                      </p>
                    </div>
                    <div
                      *ngIf="placeholder.insertType === 'customMetric'"
                      class="version-badge text-center"
                      [ngClass]="{
                        'version-two': (placeholder.edit
                          || placeholder.create) ? placeholder.isNewVersion
                          : placeholder.version === 2
                      }"
                    >
                      <span>
                        v{{
                          (placeholder.edit
                            || placeholder.create) ? placeholder.isNewVersion
                              ? '2'
                              : '1'
                            : placeholder.version
                        || '1' }}
                      </span>
                    </div>
                  </div>
                  <div class="insert-btn-block d-flex">
                    <div
                      class="wrap-content d-flex align-items-center mw-100"
                      *ngIf="placeholder.usedInPages?.length"
                    >
                      <span
                        class="badge-content"
                        [ngClass]="{
                          'no-clickable': !placeholder.usedInPages?.length
                        }"
                        (click)="openUsedInSalesConceptsModal(placeholder)"
                      >
                        {{ placeholder.usedInPages?.length }}
                      </span>
                    </div>
                    <button
                      class="btn edit-placeholder-btn"
                      (click)="addPlaceholder(placeholder)"
                    >
                      Add
                    </button>
                    <button
                      class="btn edit-placeholder-btn"
                      (mouseup)="editPlaceholder(placeholder)"
                    >
                      Edit
                    </button>
                    <button
                      class="btn delete-placeholder-btn"
                      (click)="deletePlaceholder(placeholder)"
                    >
                      Delete
                    </button>
                    <div class="order-btn-container">
                      <button
                        *ngIf="placeholder.order !== placeholders.length - 1"
                        [disabled]="
                          placeholder.order === placeholders.length - 1
                        "
                        class="order-btn-down fa fa-chevron-down h-100 w-100 rounded-circle"
                        (click)="
                          handlePlaceholdersOrder(
                            'down',
                            placeholders,
                            placeholder.id
                          )
                        "
                      ></button>
                    </div>
                    <div class="order-btn-container">
                      <button
                        *ngIf="placeholder.order !== 0"
                        [disabled]="placeholder.order === 0"
                        class="order-btn-up fa fa-chevron-up h-100 w-100 rounded-circle"
                        (click)="
                          handlePlaceholdersOrder(
                            'up',
                            placeholders,
                            placeholder.id
                          )
                        "
                      ></button>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </section>

      <section
        *ngIf="!editMode"
        class="content-section page-type-section"
        data-at-id="custom-page-form-type"
      >
        <label class="label">Page Type</label>
        <div class="custom-page-types" formArrayName="customPageType">
          <ng-container
            *ngFor="let type of customPageType.controls; let i = index"
          >
            <se-radio-button
              [label]="pageSettingsTypes[i].label"
              [checked]="pageSettingsTypes[i].selected"
              [value]="pageSettingsTypes[i].id"
              (onSelect)="onCustomPageTypeSelect(pageSettingsTypes[i])"
            ></se-radio-button>
          </ng-container>
          <span *ngIf="customPageType.invalid" class="error-message">
            At least one option should be selected
          </span>
        </div>
      </section>

      <section
        *ngIf="editMode && listOfSalesConceptsForDependentPage.length"
        class="content-section custom-pages-list-section"
        data-at-id="custom-page-used-in-sales-concepts"
      >
        <label class="label">Used in Sales Concepts</label>
        <div class="custom-page-types">
          <ul class="list-of-custom-pages">
            <li
              *ngFor="
                let item of listOfSalesConceptsForDependentPage;
                let i = index
              "
              class="custom-page-item"
            >
              <a
                href="{{ CUSTOM_PAGES_LIST_URL }}/{{ item._id }}/edit"
                target="_blank"
              >
                <span class="custom-page-name">
                  {{ i + 1 }}. {{ item.name }}
                </span>
                <span class="open-icon"></span>
              </a>
            </li>
          </ul>
        </div>
      </section>

      <section class="content-section">
        <label class="label">Bulk edit for variable inserts</label>
        <se-toggler formControlName="isBulkEditOfVariables"></se-toggler>
      </section>

      <section
        *ngIf="selectedPageType === pageTypes.endpage && endPageFormSettings"
        [formGroup]="endPageFormSettings"
        class="content-section"
      >
        <label class="label">Ordering rank (smallest at the top)</label>
        <se-form-input formControlName="orderRank"></se-form-input>
      </section>

      <ng-container
        *ngIf="salesConceptSettingsShown && salesConceptSettingsForm"
        [formGroup]="salesConceptSettingsForm"
      >
        <section class="content-section">
          <label class="label">Eligible for particular data sources</label>
          <se-toggler
            [checked]="eligibleDataSources"
            formControlName="eligibleDataSources"
            (onChange)="toggleDataSourcesSection()"
          ></se-toggler>
        </section>

        <section
          *ngIf="eligibleDataSources"
          class="data-sources"
          formArrayName="eligibleDataSourcesConfig"
        >
          <div
            *ngFor="let dataSource of dataSourcesConfig; let i = index"
            class="product-checkbox"
          >
            <se-checkbox
              [label]="dataSource.label"
              [bold]="true"
              [formControlName]="i"
              [checked]="dataSource.selected"
              (onToggle)="handleDataSourceSelection()"
            ></se-checkbox>
          </div>
        </section>

        <section
          *ngIf="eligibleDataSources"
          class="content-section error-statement"
        >
          <div class="error-statement-row">
            <label class="label">
              Error statement for unsupported data sources
            </label>
            <se-textarea
              formControlName="unsupportedDataSourcesErrorMessage"
              name="unsupportedDataSourcesErrorMessage"
              [height]="100"
              (keyup.enter)="$event.stopPropagation()"
            ></se-textarea>
          </div>
        </section>

        <section class="content-section">
          <label class="label">Eligible for particular product types</label>
          <se-toggler
            [checked]="eligibleProductsActive"
            formControlName="eligibleProductsActive"
            (onChange)="toggleProductsSection()"
          ></se-toggler>
        </section>

        <section
          *ngIf="eligibleProductsActive"
          class="product-types"
          formArrayName="eligibleProductTypes"
        >
          <div
            *ngFor="let type of productTypes; let i = index"
            class="product-checkbox"
          >
            <se-checkbox
              [label]="type.label"
              [bold]="true"
              [formControlName]="i"
              [checked]="type.selected"
              (onToggle)="handleProductTypeSelection()"
            ></se-checkbox>
          </div>
        </section>

        <section class="content-section">
          <label class="label">Dependent Pages Locked</label>
          <se-toggler formControlName="dependentPagesLocked"></se-toggler>
        </section>

        <section class="content-section">
          <label class="label">Locked and always enabled page</label>
          <se-toggler formControlName="isPageLockedAndEnabled"></se-toggler>
        </section>

        <section class="content-section">
          <label class="label">Eligible for particular carriers</label>
          <se-toggler formControlName="isEligibleForCarriers"></se-toggler>
        </section>

        <section class="content-section">
          <label class="label">Dependent Pages</label>
          <div class="dependent-pages-list">
            <ul
              *ngIf="!selectedDependentPages.length"
              class="dependent-pages-list-wrapper no-data"
            >
              <li class="dependent-pages-item">No pages selected</li>
            </ul>
            <ol
              *ngIf="selectedDependentPages.length"
              class="dependent-pages-list-wrapper"
            >
              <ng-container
                *ngFor="
                  let dependentPage of selectedDependentPages
                    | sortBy: 'asc':'order'
                "
              >
                <li class="dependent-pages-item">{{ dependentPage.label }}</li>
              </ng-container>
            </ol>
            <se-button
              [disabled]="!dependentPages?.length"
              [loading]="dependentPagesLoading"
              (click)="openDependentPagesModal()"
              class="secondary"
            >
              Configure
            </se-button>
          </div>
        </section>

        <section
          class="content-section"
          data-at-id="custom-page-form-products-min"
        >
          <label class="label">Products Min</label>
          <div class="products-counter">
            <ac-counter
              formControlName="productsMin"
              [counterRangeMax]="maxRangeForProductsMin"
              [counterRangeMin]="productsQuantityValues.min"
            ></ac-counter>
          </div>
        </section>

        <section
          class="content-section"
          data-at-id="custom-page-form-products-max"
        >
          <label class="label">Products Max</label>
          <div class="products-counter">
            <ac-counter
              formControlName="productsMax"
              [counterRangeMax]="productsQuantityValues.max"
              [counterRangeMin]="minRangeForProductMax"
            ></ac-counter>
          </div>
        </section>

        <section
          *ngIf="minRangeForProductMax > 1"
          class="content-section textarea-container"
        >
          <div class="textarea-row">
            <label class="label">
              Error statement for the insufficient number of products
            </label>
            <se-textarea
              formControlName="insufficientProductsNumberErrorMessage"
              name="insufficientProductsNumberErrorMessage"
              [height]="100"
              (keyup.enter)="$event.stopPropagation()"
            ></se-textarea>
          </div>
        </section>

        <section
          *ngIf="maxRangeForProductsMin > 1"
          class="content-section textarea-container"
        >
          <div class="textarea-row">
            <label class="label">Products Order Formula</label>
            <se-textarea
              formControlName="productsOrderFormula"
              name="productsOrderFormula"
              [height]="100"
              (keyup.enter)="$event.stopPropagation()"
            ></se-textarea>
          </div>
          <div class="formula-hint">{{ hintForProductsOrderFormula }}</div>
        </section>

        <section class="content-section">
          <label class="label">Sales Concept Description</label>
          <se-textarea
            formControlName="description"
            name="description"
            [height]="100"
            resize="none"
            [maxLength]="1000"
          ></se-textarea>
        </section>

        <section class="content-section">
          <se-file-uploader
            [config]="previewUploadConfig"
            (onUpload)="addPreviewImage($event)"
            (onRemove)="removePreviewImage()"
          ></se-file-uploader>
        </section>
      </ng-container>

      <ng-container
        *ngIf="dependentPageFormShown && dependentPageFormSettings"
        [formGroup]="dependentPageFormSettings"
      >
        <section class="content-section">
          <label class="label">Turned off by default on the presentation</label>
          <se-toggler formControlName="disabled"></se-toggler>
        </section>

        <section class="content-section">
          <label class="label">Locked and always enabled page</label>
          <se-toggler formControlName="isPageLockedAndEnabled"></se-toggler>
        </section>

        <section class="content-section">
          <label class="label">Page Display Conditionality (0 to hide)</label>
          <se-textarea
            formControlName="showHideFormula"
            name="showHideFormula"
            [height]="100"
            resize="none"
          ></se-textarea>
        </section>

        <section class="content-section textarea-container">
          <div class="textarea-row">
            <label class="label">Products Order Formula</label>
            <se-textarea
              formControlName="productsOrderFormula"
              name="productsOrderFormula"
              [height]="100"
              resize="none"
            ></se-textarea>
          </div>
          <div class="formula-hint">{{ hintForProductsOrderFormula }}</div>
        </section>
      </ng-container>

      <section
        class="content-section carriers-section"
        data-at-id="custom-page-form-carriers"
        *ngIf="showCarriers"
      >
        <label class="label">Show to Carriers({{ selectedCarriers }})</label>
        <div
          class="carriers-list"
          data-at-id="custom-page-form-carriers-list"
          formArrayName="pageCarriers"
        >
          <ng-container
            *ngFor="let carrier of pageCarriers.controls; let i = index"
          >
            <ac-control
              type="checkbox"
              [label]="carriers[i].name"
              [formControlName]="i"
              (toggle)="onCarrierSelect()"
            ></ac-control>
          </ng-container>
        </div>
      </section>

      <ng-container
        *ngIf="selectedPageType === pageTypes.endpage && endPageFormSettings"
        [formGroup]="endPageFormSettings"
      >
        <section class="content-section">
          <label class="label">Hide on Shareable Link</label>
          <se-toggler formControlName="hideOnShareableLink"></se-toggler>
        </section>
        <section class="content-section">
          <label class="label">Hide on PDF</label>
          <se-toggler formControlName="hideOnPDF"></se-toggler>
        </section>
      </ng-container>
    </form>
  </div>
</div>
