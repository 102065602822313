import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';

import { Observable, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { filter, first, switchMap, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AppState } from '../../../../../reducers';
import {
  changeFormByChartType,
  changeVariableConfiguration,
  configurationForm,
  configurationFormWithMetricQuestion,
  dynamicFormValue,
} from '../../../redux';
import { PlaceholdersDynamicFormValue, PlanConfigData, VariablePlaceholder } from '@core/model';
import { CHART_CONFIGURATION_STATE, CHART_STYLE_CONFIGURATION_STATE, VARIABLES_STATE } from '../../../constants';
import { ConfigurationData } from '../../../models';

@UntilDestroy()
@Component({
  selector: 'ep-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfigurationComponent implements OnInit {
  @Input() configurationStateId: string;
  @Input() id: string;
  @Input() plansConfigData: PlanConfigData[];

  questionsData$: Observable<{ data: any }>;
  isInlineEditMode = false;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.questionsData$ = this.getConfigurationForm().pipe(
      switchMap(data => this.modifyChartConfiguration(data)),
      switchMap(data => this.modifyChartStyleConfiguration(data)),
      first()
    );

    this.watchForDynamicFormValue();
  }

  private getConfigurationForm(): Observable<ConfigurationData> {
    return this.store.pipe(
      select(
        configurationForm({
          id: this.id,
          stateId: this.configurationStateId,
        })
      ),
      filter(item => !!item),
      untilDestroyed(this)
    );
  }

  private modifyChartConfiguration(data: ConfigurationData): Observable<ConfigurationData> {
    if (this.configurationStateId === CHART_CONFIGURATION_STATE.id) {
      return this.store.select(
        configurationFormWithMetricQuestion({
          data,
          plansConfigData: this.plansConfigData,
        })
      );
    }

    return of(data);
  }

  private modifyChartStyleConfiguration(data: ConfigurationData): Observable<ConfigurationData> {
    if (this.configurationStateId === CHART_STYLE_CONFIGURATION_STATE.id) {
      return this.modifyBasedOnDynamicFormValue(data);
    } else {
      return of(data);
    }
  }

  private modifyBasedOnDynamicFormValue(data: ConfigurationData): Observable<ConfigurationData> {
    return this.store.select(dynamicFormValue).pipe(
      first(),
      switchMap((dataSourceValue: any) =>
        this.store.select(changeFormByChartType(data, dataSourceValue.chartDataSource))
      )
    );
  }

  private watchForDynamicFormValue(): void {
    this.store
      .select(dynamicFormValue)
      .pipe(
        untilDestroyed(this),
        filter(() => this.configurationStateId === VARIABLES_STATE.id)
      )
      .subscribe((formValue: PlaceholdersDynamicFormValue) => {
        this.isInlineEditMode = (formValue as VariablePlaceholder)?.isInlineEditable;
        this.questionsData$ = this.getModifiedVariableFormConfiguration(formValue as VariablePlaceholder);
      });
  }

  private getModifiedVariableFormConfiguration(formValue: VariablePlaceholder): Observable<ConfigurationData> {
    return this.getConfigurationForm().pipe(
      first(),
      map((data: ConfigurationData) => changeVariableConfiguration(data, formValue))
    );
  }
}
