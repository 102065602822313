<!--TODO: need to find another way to handle such case-->

<se-dynamic-form
  *ngIf="(questionsData$ | async) && !isInlineEditMode"
  [questionsData]="questionsData$"
  [formId]="configurationStateId"
  [params]="{}"
></se-dynamic-form>

<se-dynamic-form
  *ngIf="(questionsData$ | async) && isInlineEditMode"
  [questionsData]="questionsData$"
  [formId]="configurationStateId"
  [params]="{}"
></se-dynamic-form>
