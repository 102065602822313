import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Resolve, Router } from '@angular/router';

import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import * as _ from 'lodash-es';
import { forkJoin, throwError, Observable, of, combineLatest } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { tap, filter, first } from 'rxjs/operators';
import * as moment from 'moment';

import {
  globalCoverLetterLoadPending,
  globalCoverLetterLoadSuccess,
  globalCoverLetterLoadFailure,
  coverSheetLandscape,
} from '@ngrx-app/global.actions';
import { AppState } from '../reducers';
import { isPDF } from '@core/utils';

import { ThemesAPIService } from '@assurance/um-services';

import { CoverSheetModalService } from '../components/presentation/modals/cover-sheet-modal/cover-sheet-modal.service';
import {
  presentationRulesLoadPending,
  presentationRulesLoadSuccess,
  presentationRulesLoadFailure,
  presentationXAxisSourceUpdateSuccess,
  presentationDataLoadFailure,
  presentationDataLoadSuccess,
  presentationDataLoadPending,
  presentationConfigsSettingSuccess,
  presentationConfigsSettingPending,
  presentationConfigsSettingFailure,
} from '../components/presentation/presentation.actions';
import { PresentationService } from '../components/presentation/presentation.service';
import { CustomPagesConfig, GlobalConfig, PageConfig } from '@shared/models';
import { CustomPageService } from '../components/presentation/setup/custom-page/custom-page.service';
import { getDependentPermissions, getProfileGroupRules } from '@ngrx-app/global.selectors';
import { getGraphValue, getPresentationConfigs } from '../components/presentation/redux/configs/selectors';
import { TimeFormat } from '@core/constant';
import { PlansService } from '../components/presentation/presentation-plans/plans.service';
import { Presentation } from '@core/model';
import { APIService, Global, NavbarPagesService, StyleEditorService } from '@shared/services';
import { StyleSchemaService } from '@core/service';
import {
  getDependentPagesConfigLoadingSuccess,
  getSalesConceptsCountLoadingSuccess,
} from '../components/presentation/setup/setup.actions';

@Injectable()
export class SetupConfGuard implements Resolve<any> {
  constructor(
    private global: Global,
    private presentationService: PresentationService,
    private apiService: APIService,
    private store: Store<AppState>,
    private coverSheetModalService: CoverSheetModalService
  ) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const id = +route.paramMap.get('id');
    const view = route.firstChild?.data?.view;
    this.coverSheetModalService.getCoverSheetTemplates().subscribe();
    this.store.dispatch(presentationConfigsSettingPending());

    return this.global.getPageConfig(id, true, view).pipe(
      switchMap((configs: (GlobalConfig & PageConfig)[]) => {
        this.store.dispatch(presentationConfigsSettingSuccess({ data: configs }));
        const page = _.find(configs, 'pageName');
        this.store.dispatch(
          presentationXAxisSourceUpdateSuccess({
            xAxisSource: {
              value: _.get(page, 'config.chartConfig.xAxisSource') as any,
            },
          })
        );

        const globalConfig = configs[0];
        this.global.presentationSettings.pdfPrintAll = globalConfig.config.pdfPrintAll;

        return this.setMaxAgeConfiguration(configs);
      }),
      switchMap(() => {
        const carrierPlans = this.global.getCurrentCarrierPlans;

        return this.apiService.getCarrierPlansConfig(carrierPlans && carrierPlans[0] && carrierPlans[0].app).pipe(
          tap(configs => (this.global.setPlansConfig = configs.data)),
          catchError(error => {
            return throwError(`${error.status} - ${error.statusText}`);
          })
        );
      }),
      catchError(error => {
        this.store.dispatch(presentationConfigsSettingFailure({ error }));

        return throwError(`${error.status} - ${error.statusText}`);
      })
    );
  }

  private setMaxAgeConfiguration(configs: (GlobalConfig & PageConfig)[]): Observable<any> {
    this.global.presentationSettings.minAge = configs[0].config.minAgeDisplay;
    this.global.presentationSettings.maxAge = configs[0].config.maxAgeDisplay;
    this.store.dispatch(presentationRulesLoadPending());

    return forkJoin([
      this.store.pipe(select(getProfileGroupRules), first()),
      this.store.pipe(select(getGraphValue), first()),
    ]).pipe(
      tap(([rulesResponse, graphValue]) => {
        let rules = rulesResponse.reduce(
          (obj, value) => ({
            ...obj,
            [value.ruleKey]: value.ruleValue,
          }),
          {}
        );

        if (graphValue !== 'eoy_age') {
          rules = {
            ...rules,
            ...this.presentationService.recalculateShortfallPolicyValues(rules, graphValue),
          };
        }

        this.store.dispatch(presentationRulesLoadSuccess({ data: rules }));
      }),
      catchError(error => {
        this.store.dispatch(presentationRulesLoadFailure({ error }));

        return throwError(error);
      })
    );
  }
}

@Injectable()
export class CurrentThemeFetch implements CanActivate, CanActivateChild {
  constructor(
    private global: Global,
    private styleService: StyleEditorService,
    private themesAPIService: ThemesAPIService,
    private styleSchemaService: StyleSchemaService
  ) {}

  public canActivate(): Observable<boolean> | boolean {
    if (this.global.currentTheme) {
      return true;
    } else {
      const getCurrentTheme = this.themesAPIService.getCurrentTheme().pipe(map(response => response.data));
      const getDefaultTheme = this.themesAPIService.getDefaultTheme().pipe(map(response => response.data));

      return forkJoin([getCurrentTheme, getDefaultTheme]).pipe(
        // results: Theme[]  or UiTheme[]
        map((results: any[]) => {
          this.styleSchemaService.setDefaultUiTheme = results[1];
          this.styleService.populateMainThemes(results);

          return true;
        }),
        catchError(err => {
          return of(err);
        })
      );
    }
  }

  public canActivateChild(): Observable<boolean> | boolean {
    return this.canActivate();
  }
}

@Injectable()
export class SharedCurrentThemeFetch implements CanActivate, CanActivateChild {
  constructor(private global: Global, private styleService: StyleEditorService, private apiService: APIService) {}

  public canActivate(): Observable<boolean> | boolean {
    if (this.global.currentTheme) {
      return true;
    } else {
      return this.apiService.getCurrentTheme().pipe(
        map(result => {
          this.styleService.populateMainThemes([result.data, null]);

          return true;
        })
      );
    }
  }

  public canActivateChild(): Observable<boolean> | boolean {
    return this.canActivate();
  }
}

@Injectable()
export class MetadataList implements Resolve<any> {
  constructor(private apiService: APIService, private global: Global) {}

  public resolve(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (_.get(this.global, 'metadata')) {
        resolve(true);
      } else {
        this.apiService.getPresentationMetadataList().subscribe(
          (res: any) => {
            this.global.metadata = res.data;
            resolve(true);
          },
          err => reject(err)
        );
      }
    });
  }
}

@Injectable()
export class PresentationGuard implements CanActivate {
  constructor(
    public router: Router,
    private apiService: APIService,
    private global: Global,
    private store: Store<AppState>
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<any> {
    this.store.dispatch(presentationDataLoadPending());

    if (this.global.getPresentation) {
      this.store.dispatch(
        presentationDataLoadSuccess({
          data: { ...this.global.getPresentation },
        })
      );

      return of(this.global.getPresentation);
    }

    return this.apiService.getPresentationConfig(+route.paramMap.get('id')).pipe(
      filter((presentation: { data: Presentation }) => !!presentation),
      map((presentation: { data: Presentation }) => {
        const data = {
          agencyId: presentation.data.agencyId,
          assigneeId: presentation.data.assigneeId,
          clientname: presentation.data.clientname,
          formattedDate: this.formatDate(presentation.data.date),
          formattedUpdateDate: this.formatDate(presentation.data.update_date),
          isShared: presentation.data.isShared,
          isTemplate: presentation.data.isTemplate,
          isSystemTemplate: presentation.data.isSystemTemplate,
          isDistributed: presentation.data.isDistributed,
          description: presentation.data.description,
          name: presentation.data.name,
          sharedWith: presentation.data.sharedWith,
          userid: presentation.data.userid,
          productType: presentation.data.productType,
          managerId: presentation.data.managerId,
          organizations_distributed_to: presentation.data.organizations_distributed_to,
          groups_distributed_to: presentation.data.groups_distributed_to,
          createdByName: presentation.data.createdByName,
          id: presentation.data.id,
          hidePIIData: presentation.data.hidePIIData,
          salesStorySettingsShown: presentation.data.salesStorySettingsShown,
          shareableToken: presentation.data?.shareableToken,
        };
        this.global.setPresentation = data;
        this.store.dispatch(presentationDataLoadSuccess({ data: { ...data } as any }));
      }),
      catchError(err => {
        this.store.dispatch(presentationDataLoadFailure({ error: err }));

        return throwError(`${err.status} - ${err.statusText}`);
      })
    );
  }

  private formatDate(date: string) {
    return moment.utc(date).format(TimeFormat.MMDDYYYY);
  }
}

@Injectable()
export class ThemesList implements Resolve<any> {
  constructor(private global: Global, private themesAPIService: ThemesAPIService) {}

  public resolve() {
    return new Promise((resolve, reject) => {
      if (_.isEmpty(this.global.themesList)) {
        this.themesAPIService.getAllThemes().subscribe(res => {
          this.global.themesList = res.data;
          resolve(true);
        }, reject);
      } else {
        resolve(true);
      }
    });
  }
}

@Injectable()
export class CoverLetterGuard implements Resolve<any> {
  constructor(private apiService: APIService, private store: Store<AppState>) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const params: any = route.params;
    const id = +params.id;

    this.store.dispatch(globalCoverLetterLoadPending());

    return this.apiService.getCoverLetter(id).pipe(
      map(response => {
        const configs = !_.isEmpty(response.data.configs) ? response.data.configs : null;
        this.store.dispatch(globalCoverLetterLoadSuccess({ payload: configs }));

        if (configs && configs.uiId) {
          this.updateCoverSheetParams(configs);
        }

        return configs;
      }),
      catchError(error => {
        this.store.dispatch(globalCoverLetterLoadFailure({ error }));

        return throwError(`${error.status} - ${error.statusText}`);
      })
    );
  }

  private updateCoverSheetParams(configs: any) {
    this.store.dispatch(
      coverSheetLandscape({
        payload: configs.uiId !== 'allianz' && configs.uiId !== 'allianzretirement',
      })
    );
  }
}

@Injectable()
export class CoverLetterRequiredGuard implements Resolve<any> {
  constructor(private coverSheetModalService: CoverSheetModalService, private plansService: PlansService) {}
  public resolve(): Observable<any> {
    return this.plansService.checkOnAnnuityProducts()
      ? of(null)
      : this.coverSheetModalService.getCoverSheetRequiredTemplates();
  }
}

@Injectable()
export class ContentPages implements Resolve<any> {
  constructor(
    private store: Store<AppState>,
    private customPageService: CustomPageService,
    private global: Global,
    private navbarPagesService: NavbarPagesService,
    private apiService: APIService
  ) {}

  public resolve(route?: ActivatedRouteSnapshot): Observable<any> {
    return this.store.pipe(
      select(getDependentPermissions),
      withLatestFrom(this.store.pipe(select(getPresentationConfigs))),
      switchMap(([dependentPermissions, configs]) => {
        const customPages = {
          endPages: dependentPermissions?.requiredEndPages || [],
          salesConcepts: dependentPermissions?.salesConcepts || [],
        };
        const id =
          route instanceof ActivatedRouteSnapshot ? +route.paramMap.get('id') : this.global.getActivePresentationId;

        // PDF Generation: loads only necessary custom pages.
        if (route instanceof ActivatedRouteSnapshot && route.queryParams && route.queryParams.pdfPage) {
          const pages = _.flatten([route.queryParams.pdfPage]);
          const pdfPageParentUiId = _.flatten([route.queryParams.pdfPageParentUiId]);
          customPages.endPages = route.queryParams.isEndPage ? pages : null;
          customPages.salesConcepts = route.queryParams.isSalesConcept ? pages : null;

          if (route.queryParams.isDependentPage) {
            customPages.salesConcepts = pdfPageParentUiId;
          }
        }

        const salesConceptsCount = customPages?.salesConcepts?.length;

        if (salesConceptsCount) {
          this.store.dispatch(
            getSalesConceptsCountLoadingSuccess({
              payload: salesConceptsCount,
            })
          );
        }

        const resource = isPDF
          ? this.resolvePDF(id, customPages, configs)
          : this.resolveSetupViewAndShare(id, configs, customPages.endPages, customPages.salesConcepts);

        return resource.pipe(first());
      }),
      first()
    );
  }

  private resolveSetupViewAndShare(id, configs, endPages, concepts): Observable<any> {
    const commonUiIds = this.customPageService.getCommonUiIds({ uiId: concepts }, configs);

    return combineLatest([
      this.customPageService.getDependentPagesConfig(id, false).pipe(
        switchMap(data => this.getExistOrNewDependentPageConfig(id, configs, data?.data?.configs?.configs)),
        switchMap(data => this.navbarPagesService.getNavbarCustomPages(configs, data, endPages, commonUiIds))
      ),
    ]);
  }

  private resolvePDF(id, customPages, configs): Observable<any> {
    return combineLatest([
      this.customPageService.getDependentPagesConfig(id, true),
      customPages.endPages
        ? this.customPageService.getCarrierEndPages({
            uiId: customPages.endPages,
            labels: ['endpage'],
          })
        : this.customPageService.getCarrierEndPages(null),
      customPages.salesConcepts
        ? this.customPageService.getExistingSalesConcept({ uiId: customPages.salesConcepts }, configs)
        : of(true),
    ]);
  }

  private getExistOrNewDependentPageConfig(
    id: number,
    configs: PageConfig[],
    depConfigs
  ): Observable<Partial<CustomPagesConfig[]>> {
    if (this.global.isSharedPresentation() && !depConfigs) {
      return of([]);
    }

    return depConfigs ? of(depConfigs) : this.createNewDependentPagesConfig(id, configs);
  }

  private getNewConfigs(configs: PageConfig[]): Partial<CustomPagesConfig>[] {
    return configs
      .filter((item: PageConfig) => item.config?.uiId)
      .map((item: PageConfig) => ({ uiId: item.config.uiId, pages: [] }));
  }

  private createNewDependentPagesConfig(id: number, configs: PageConfig[]): any {
    const newConfigs = this.getNewConfigs(configs);

    return this.apiService.putDependentPageConfig(id, newConfigs).pipe(
      tap(data => {
        this.store.dispatch(
          getDependentPagesConfigLoadingSuccess({
            payload: data.data.configs,
          })
        );
      }),
      map(data => data.data.configs?.configs || [])
    );
  }
}

@Injectable()
export class PresentationLoader implements CanActivate {
  constructor(
    public metadata: MetadataList,
    public setupConf: SetupConfGuard,
    public coverLetter: CoverLetterGuard,
    public coverLetterRequired: CoverLetterRequiredGuard,
    public contentPages: ContentPages
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    // PDF Generation: need to avoid unnecessary API call for PDF route.
    const queryParams = route.queryParams;

    return this.setupConf.resolve(route).pipe(
      switchMap(() => {
        const isCoverLetter = queryParams && queryParams.pdfPage ? queryParams.isCoverLetter : true;
        const isCustomPage =
          queryParams && queryParams.pdfPage
            ? queryParams.isSalesConcept || queryParams.isEndPage || queryParams.isDependentPage
            : true;

        return combineLatest([
          this.metadata.resolve(),
          isCoverLetter ? this.coverLetter.resolve(route) : of(true),
          isCoverLetter ? this.coverLetterRequired.resolve() : of(true),
          isCustomPage ? this.contentPages.resolve(route) : of(true),
        ]).pipe(first());
      }),
      map(response => !!response)
    );
  }
}
