export enum INSERT_TYPE {
  chart = 'chart',
  text = 'text',
  variable = 'variable',
  customMetric = 'customMetric',
  image = 'image',
  productsDescription = 'productsDescription',
  customTable = 'customTable',
  button = 'button',
  apiCall = 'apiCall',
  fromOtherPage = 'fromOtherPage',
  dropdown = 'dropdown',
  dropdownVariable = 'dropdownVariable',
  productSelector = 'productSelector',
  tab = 'tab',
}

export enum InsertVariableValueTypes {
  currency = 'currency',
  percentage = 'percentage',
  number = 'number',
  textField = 'textField',
  textArea = 'textArea',
}
