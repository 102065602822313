import { createAction, props } from '@ngrx/store';

import { PresentationSettings } from '../../../modals/presentation-settings/settings.models';
import { ShortfallFields } from '@shared/models';
import { PlansTableField, XAxisSourceType } from '@core/model';

export const updateSettingsConfigs = createAction(
  '[Configs - Settings] Update Configs',
  props<{ settings: PresentationSettings }>()
);

export const saveSettingsConfigs = createAction(
  '[Configs - Settings] Save Configs',
  props<{ settings: PresentationSettings }>()
);

export const updateConfigLockedPresentation = createAction(
  '[Configs - Settings] Update Locked Presentation',
  props<{ values: { locked: boolean } }>()
);

export const updateConfigSubmitApplicationButton = createAction(
  '[Configs - Settings] Update Submit Application Button',
  props<{ values: { showSubmitApplicationButtonVisible: boolean } }>()
);

export const updateConfigPrintAll = createAction(
  '[Configs - Settings] Update Print All',
  props<{ values: { pdfPrintAll: boolean } }>()
);

export const updateConfigMaxAge = createAction(
  '[Configs - Settings] Update Max Age',
  props<{ values: { maxAgeDisplay: boolean } }>()
);

export const updateConfigMinAge = createAction(
  '[Configs - Settings] Update Min Age',
  props<{ values: { minAgeDisplay: boolean } }>()
);

export const updateConfigIRR = createAction(
  '[Configs - Settings] Update IRR',
  props<{ values: { topIrr: number; bottomIrr: number } }>()
);

export const updateConfigChartConfig = createAction(
  '[Configs - Settings] Update Chart Config',
  props<{
    values: Array<{
      uiId: string;
      content: {
        xAxisSource: XAxisSourceType;
        xMin?: number;
        xMax?: number;
      };
    }>;
  }>()
);

export const updateConfigShortfallSettings = createAction(
  '[Configs - Settings] Update Shortfall Settings',
  props<{
    values: Array<{
      uiId: string;
      content: ShortfallFields;
    }>;
    disableSave?: boolean;
  }>()
);

export const updateConfigPlanMetricsTipValue = createAction(
  '[Configs - Settings] Update Plan Metrics Tip Value',
  props<{
    values: Array<{
      uiId: string;
      content: Array<{ key: string; tipValue: number }>;
    }>;
    disableSave?: boolean;
  }>()
);

export const updateConfigPlansTableFields = createAction(
  '[Configs - Settings] Update Plans Table FIelds',
  props<{
    values: { plansTableFields: PlansTableField[] };
    disableSave?: boolean;
  }>()
);

export const prefillTableFieldsYears = createAction('[Configs - Settings] Prefill Table Fields Years');
