import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class InlineEditVariablesService {
  private saveValues = new Subject<void>();

  emitSave(): void {
    this.saveValues.next();
  }

  watchForEmit(): Observable<void> {
    return this.saveValues.asObservable();
  }
}
